
let downloadFile = {

    /**
     * 文件下载
     * @param url  文件url
     * @param fileName  文件名
     */
    doExecute(url, fileName) {
        fetch(url).then(res => res.blob()).then((blob => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // 兼容IE
                window.navigator.msSaveOrOpenBlob(blob, fileName); // 可以自定义文件名称及后缀
            } else { // 兼容Google及fireFox
                let a = document.createElement('a')
                document.body.appendChild(a)
                a.style = 'display: none'
                let _url = window.URL.createObjectURL(blob) // 创建url
                a.href = _url
                a.download = fileName
                a.click()
                document.body.removeChild(a)
                window.URL.revokeObjectURL(_url) // 释放url
            }
        }))
    },

    getFileType(filePath) {
        var startIndex = filePath.lastIndexOf(".")
        if (startIndex != -1) {
            return filePath.substring(startIndex + 1, filePath.length).toLowerCase()
        } else {
            return ""
        }
    }
}

export default downloadFile
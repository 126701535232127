var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config
    ? _c("div", { staticClass: "detail-box" }, [
        _c(
          "div",
          {
            staticClass: "title-box",
            style: _vm.config.listCard ? "background:var(--themeColor)" : "",
          },
          [
            _c("i-header", {
              attrs: {
                title: _vm.config["title"] ? _vm.config["title"].template : "",
                theme: _vm.config.listCard ? "light" : "",
              },
              on: { "back-event": _vm.toBack },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "cnt-box",
            style: _vm.$route.query.readOnly
              ? "height:calc(100vh - 0.44rem)"
              : "",
            attrs: { id: "scrollCnt" },
          },
          [
            _vm.config.listCard
              ? _c(
                  "div",
                  { staticClass: "card-box" },
                  [
                    _c("ListCard", {
                      staticStyle: { "margin-bottom": "0.3rem" },
                      attrs: {
                        "show-btn": false,
                        template: _vm.config.listCard.template,
                        info: _vm.$smartStorage.get("genericDetailInfo"),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.template.length > 0
              ? _c(
                  "div",
                  { staticClass: "form-box" },
                  [
                    !_vm.$route.query.readOnly
                      ? _c(
                          "i-form",
                          {
                            ref: "form",
                            attrs: { "scroll-to-error": "" },
                            on: { submit: _vm.onSubmit },
                          },
                          _vm._l(_vm.template, function (item, index) {
                            return _c("iFormItem", {
                              key: index,
                              attrs: {
                                "form-data": _vm.formData,
                                formTemplate: [_vm.template],
                                "model-val": _vm.formData[item.controlName],
                                "form-item": item,
                              },
                              on: { callBack: _vm.fromCallback },
                            })
                          }),
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "info-box" },
                          _vm._l(_vm.template, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "info-box-item" },
                              [
                                _c("div", { staticClass: "info-item-title" }, [
                                  _vm._v(_vm._s(item.label)),
                                ]),
                                _c("div", { staticClass: "info-item-cnt" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formData[item.controlName] || "暂无"
                                    )
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                    _vm._l(_vm.fileList, function (file, index) {
                      return _c("iFinderFrame", {
                        key: index,
                        attrs: { uploaderSettings: file },
                      })
                    }),
                    _vm.info.agreementURL && _vm.info.canEditMiceAgreement
                      ? _c(
                          "div",
                          {
                            staticClass: "download-file",
                            on: {
                              click: function ($event) {
                                return _vm.downloadFile(_vm.info.agreementURL)
                              },
                            },
                          },
                          [_vm._v("点击下载《讲者协议函》")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.$route.query.readOnly,
                expression: "!$route.query.readOnly",
              },
            ],
            staticClass: "confirm-box",
          },
          [
            _c(
              "i-button",
              {
                attrs: {
                  round: "",
                  color: "var(--themeColor)",
                  block: "",
                  loading: _vm.btnLoading,
                },
                on: { click: _vm.btnClick },
              },
              [_vm._v("确定")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }